import { IDropdownOption, Label } from '@fluentui/react';
import { useEventApi } from 'event/file_note/FileNoteApiHooks';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FileError } from 'react-dropzone';

import { DocumentDto, FileNoteDto, FileNoteDtoRestrictedTypeEnum } from 'api-client';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import DatePicker from 'common/controls/inputs/DatePicker';
import Dropdown from 'common/controls/inputs/Dropdown';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { TextField } from 'common/controls/inputs/TextField';
import { formatDateTimeFromISO } from 'common/utils/dateUtils';
import { doDownload } from 'common/utils/download';
import FileUpload, { DrmDocument } from 'document_management/components/FileUploadRrf';

import { EntityType } from './FileNoteSection';

interface FileNoteFormProps {
  existingFileNote: FileNoteDto;
  readOnly: boolean;
  mode: string;
  entityType: EntityType;
  noteTypes: any[];
}

const FileNoteForm: React.FC<FileNoteFormProps> = (props) => {
  const [existingFiles, setExistingFiles] = useState<DrmDocument[]>([]);
  const formikContext = useFormikContext<any>();
  const { hasPermission } = useAuth();
  const canSearchAllRestrictedTypes = hasPermission('SEARCH_ALL_RESTRICTED_TYPE');
  const restrictedTypes = Object.values(FileNoteDtoRestrictedTypeEnum);
  const { getFileNoteAttachment } = useEventApi();

  const fileNoteRestrictedTypeOptions = !canSearchAllRestrictedTypes
    ? [...restrictedTypes.map((s) => ({ key: s, text: s })).filter((restrictedType) => FileNoteDtoRestrictedTypeEnum.Private === restrictedType.key)]
    : ([...restrictedTypes.map((s) => ({ key: s, text: s }))] as IDropdownOption[]);

  useEffect(() => {
    if (formikContext.values?.fileNoteDrmIds?.length !== existingFiles?.length && formikContext.values?.fileNoteDrmIds?.length > 0) {
      setExistingFiles(formikContext.values?.fileNoteDrmIds.map((x: DocumentDto) => ({ drmId: x.drmId, name: x.fileName! })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikContext.values?.fileNoteDrmIds]);

  const handleFileRemoved = (file: DrmDocument): Promise<void> => {
    const { values } = formikContext;
    formikContext.setFieldValue(
      'fileNoteDrmIds',
      values.fileNoteDrmIds.filter((x: DocumentDto) => x.drmId !== file.drmId),
    );
    return Promise.resolve();
  };

  const handleFileUploaded = (file: DrmDocument): Promise<void> => {
    const { values } = formikContext;
    formikContext.setFieldValue('fileNoteDrmIds', [...(values.fileNoteDrmIds ?? []), { drmId: file.drmId, fileName: file.name }]);
    return Promise.resolve();
  };

  const fileValidator = (file: File): FileError | null => {
    if (file.size > 100000 * 1024) {
      return {
        code: 'rrf-file-is-too-large',
        message: 'The file selected is too large. Please select a file that is no greater than 100MB.',
      };
    }
    return null;
  };

  const handleFileView = async (file: DrmDocument) => {
    const documentResource = await getFileNoteAttachment(file.drmId);
    doDownload(new Blob([documentResource]), file.name);
  };

  const formatTime = (val: any) => {
    if (val && val.match(/\d{4}/)) {
      return `${val.substring(0, 2)}:${val.substring(2)}`;
    }
    return val;
  };

  const labelStyle: React.CSSProperties =
    props.mode === 'view'
      ? {
          fontWeight: 'normal',
        }
      : {};

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col lg={3}>
          <DatePicker label="File note date" name="noteDate" maxDate={new Date()} required dateOnlyAsString />
        </Grid.Col>
        <Grid.Col lg={3}>
          <TextField
            label="File note Time"
            name="noteTime"
            data-automation-id="noteTime"
            required
            placeholder="HH:MM"
            readOnlyEmptyFormat=" "
            maxLength={5}
            formatText={formatTime}
          />
        </Grid.Col>
        <Grid.Col lg={6}>
          <Dropdown
            label="File note type"
            name="type"
            options={props.readOnly ? props.noteTypes : props.noteTypes.filter((x) => x.onlyMigrated === undefined)}
            required
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={6}>
          <TextField name="noteTitle" label="Note title" required maxLength={160} showMaxHint />
        </Grid.Col>
        {props.entityType === 'CASE' && (
          <Grid.Col lg={6}>
            <TextField
              name="timeSpent"
              label="Time spent"
              placeholder="HH:MM"
              readOnlyEmptyFormat=" "
              required
              maxLength={5}
              formatText={formatTime}
            />
          </Grid.Col>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={6}>
          <Dropdown label="Restricted" name="restrictedType" options={fileNoteRestrictedTypeOptions} required />
        </Grid.Col>
        <Grid.Col lg={6}>
          <TextField name="noteDetail" label="Note details" multiline maxLength={2000} showMaxHint />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={6}>
          <Label style={labelStyle}>Upload file</Label>
          <FileUpload
            id="file-note-attachment-upload"
            onFileRemoved={handleFileRemoved}
            onFileUploaded={handleFileUploaded}
            documentType="FILENOTEATTACH"
            validator={fileValidator}
            formFieldName="fileNoteDrmIds.drmId"
            existingFiles={existingFiles ? existingFiles : []}
            canViewFile={existingFiles && existingFiles.length > 0 ? existingFiles[0].name !== 'Document Not Found' : true}
            onFileView={handleFileView}
            readonly={props.readOnly}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={6}>
          <ReadOnlyField label="Created by" name={'createdName'} />
        </Grid.Col>
        <Grid.Col lg={6}>
          <ReadOnlyField label="Created date" name={'createdDate'} renderFormat={formatDateTimeFromISO} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={6}>
          <ReadOnlyField label="Last updated by" name={'lastUpdatedName'} />
        </Grid.Col>
        <Grid.Col lg={6}>
          <ReadOnlyField label="Last updated date" name={'lastUpdatedDate'} renderFormat={formatDateTimeFromISO} />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default FileNoteForm;
