import { useEventApi } from 'event/file_note/FileNoteApiHooks';
import { Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';

import { FormMode } from 'common/form/Form';
import InlineForm from 'common/form/InlineForm';

import {
  concatenatesDateTime,
  formatDateFromISO,
  formatTime,
  ISO_8601_NO_TIME,
  toISODateString,
  getCurrentFormatedTime,
} from '../../../common/utils/dateUtils';
import FileNoteForm from './FileNoteForm';
import { EntityType } from './FileNoteSection';
import { fileNoteFormSchema } from './validationSchema';

interface FileNoteRowProps {
  fileNote: any;
  entityId: number;
  entityType: EntityType;
  noteTypes: any[];
  hideForm: () => void;
  mode: 'create' | 'update' | 'view';
  addFileNote: (fileNoteDto: any) => void;
  readOnly: boolean;
}

const FileNoteRow: React.FC<FileNoteRowProps> = (props) => {
  const { saveFileNote } = useEventApi();
  const [readOnly, setReadOnly] = useState<boolean>(props.readOnly);
  const initValues =
    props.mode === 'create'
      ? {
          ...props.fileNote,
          entityId: props.entityId,
          entityType: props.entityType,
          noteDate: props.fileNote.noteDate ?? new Date(toISODateString(new Date()) as string),
          noteTime: props.fileNote.noteDate ? formatTime(props.fileNote.noteDate.split('T')[1]).slice(0, -3) : getCurrentFormatedTime(),
          type: props.fileNote.type ?? null,
          noteTitle: props.fileNote.noteTitle ?? null,
          timeSpent: props.fileNote.timeSpent ?? null,
          restrictedType: props.fileNote.restrictedType ?? null,
          fileNoteDrmIds: props.fileNote.fileNoteDrmIds ?? null,
          noteDetail: props.fileNote.noteDetail ?? null,
          createdName: props.fileNote.createdName ?? null,
          createdDate: props.fileNote.createdDate ?? null,
          lastUpdatedName: props.fileNote.lastUpdatedName ?? null,
          lastUpdatedDate: props.fileNote.lastUpdatedDate ?? null,
        }
      : {
          ...props.fileNote,
          noteTime: props.fileNote.noteDate ? formatTime(props.fileNote.noteDate.split('T')[1]).slice(0, -3) : getCurrentFormatedTime(),
          noteDate: props.fileNote.noteDate ? formatDateFromISO(props.fileNote.noteDate) : undefined,
        };

  const additionalButtons = (mode: FormMode) => {
    return mode === 'EDIT'
      ? []
      : [
          {
            id: `${mode === 'CREATE' ? 'cancel' : 'close'}-file-note-form`,
            onClick: props.hideForm,
            text: mode === 'CREATE' ? 'Cancel' : 'Close',
            shouldConfirm: mode === 'CREATE',
          },
        ];
  };

  const existingFileNote = props.fileNote;

  return (
    <Formik
      initialValues={initValues}
      onSubmit={(values) => {
        const convertedValues = {
          ...values,
          noteDate: concatenatesDateTime(formatDateFromISO(values.noteDate, ISO_8601_NO_TIME), values.noteTime),
        };
        delete convertedValues['noteTime'];
        saveFileNote(convertedValues as any).then(props.addFileNote);
        props.hideForm();
      }}
      validationSchema={fileNoteFormSchema}
    >
      <InlineForm
        id={`${props.mode}-file-note-form`}
        hideFormButtonsTop
        showFormButtonsBottom
        disableErrorMessage
        hideBack
        canEdit={props.mode === 'create' || props.mode === 'update'}
        mode={props.mode === 'create' ? 'CREATE' : 'VIEW'}
        additionalButtons={additionalButtons}
        onEditClick={() => {
          setReadOnly(false);
        }}
        onCancelEdit={() => {
          setReadOnly(true);
        }}
      >
        <FileNoteForm
          existingFileNote={existingFileNote}
          readOnly={readOnly}
          mode={props.mode}
          entityType={props.entityType}
          noteTypes={props.noteTypes}
        />
      </InlineForm>
    </Formik>
  );
};

export default FileNoteRow;
