import * as React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { UserSearchCriteria } from 'api-client';

import ProtectedRoute from 'common/auth/ProtectedRoute';
import { stringifyQueryObject } from 'search/utils';

const CreateInternalUserPage = React.lazy(() => import('./create/CreateInternalUserPage'));
const MaintainUserPage = React.lazy(() => import('./maintain/MaintainUserPage'));
const MyUserAccountPage = React.lazy(() => import('./maintain/MyUserAccountPage'));
const SearchUserToMaintainPage = React.lazy(() => import('./search/SearchUserToMaintainPage'));
const MaintainExportMaskPage = React.lazy(() => import('../../common/user/components/export_mask/MaintainExportMaskPage'));

export const useUserNavigation = () => {
  const history = useHistory();

  return {
    navigateToCreateInernalUser: () => history.push('/ui/app/user/create'),
    navigateToMyUserAccount: () => history.push('/ui/app/user/my-account'),
    navigateToMaintainUser: (userId: number) => history.push(`/ui/app/user/maintain/${userId}`),
    navigateToSearchUserToMaintain: (criteria?: UserSearchCriteria, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/user/search?${stringifyQueryObject(criteria)}`)
        : history.push(`/ui/app/user/search?${stringifyQueryObject(criteria)}`),
    navigateToOnboarding: () => history.push(`/ui/app/onboarding`),
    navigateToMaintainExportMask: (maskId: number) => history.push(`/ui/app/user/export-mask/maintain/${maskId}`),
  };
};

const UserRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <ProtectedRoute path={`${path}/create`} component={CreateInternalUserPage} permission={['CREATE_INT_USER']} />
        <ProtectedRoute path={`${path}/maintain/:userId`} component={MaintainUserPage} permission={['VIEW_UPDATE_USER_PROFILE']} />
        <ProtectedRoute path={`${path}/my-account`} component={MyUserAccountPage} />
        <ProtectedRoute path={`${path}/search`} component={SearchUserToMaintainPage} permission={['VIEW_UPDATE_USER_PROFILE']} />
        <ProtectedRoute path={`${path}/export-mask/maintain/:maskId`} component={MaintainExportMaskPage} />
      </Switch>
    </>
  );
};

export default UserRouter;
